import Footer from './footer';

const BarberShop = () => {
    return (
      <>
        <main>
          <div class="card">
              <div class="inner">
                <h1 className="h1-header">Barbershop</h1>
                <p class="subtitle">W studio przy ulicy Wronieckiej 16/3, rezyduje dwóch Panów barberów, których umiejętności zadowolą nawet najbardziej wyszukane gusta.</p>
                <p class="subtitle">Kuba i Maciej są uczestnikami profesjonalnych szkoleń, gdzie wciąż doskonalą swoje umiejętności fryzjerskie. Swoją pracę opierają <span className="sierotki">na uznanych</span><span className="sierotki">i sprawdzonych</span> markach: Reuzel, Uppercut, Layrite, Proraso, Pomp&Co.</p>
                <p class="subtitle">Jesteś wielbicielem pomady na włosy, czy też ceniącym pachnące balsamy brodaczem: gwarantujemy, że będziesz zadowolony. Jeśli jeszcze nie byłeś gościem na naszych fotelach, <span className="sierotki">to zapraszamy</span> <span className="sierotki">w nasze</span> skromne progi. Swoją wizytę zarezerwuj przez aplikację Booksy lub dzwoń bezpośrednio <span className="sierotki">do studio.</span></p>
              </div>
          </div>
        </main>
          <section>
            <div className="container-sm padding-5">
                <div className="row">
                  <div className="artistBox col-md-6 col-sm-6 text-center">
                      <img className="artistImg" src="/inc/artysci/artist_15.jpg" />
                      <span className="artistName">Kuba (kuba_od_fryzur)</span>
                      <span className="artistProffesion">Barber</span>
                      <a className="portfolioButton" href="https://instagram.com/kuba_od_fryzur?igshid=YmMyMTA2M2Y=" target="_blank">Portfolio</a>
                  </div>
                  {/* <div className="artistBox col-md-6 col-sm-6 text-center">
                      <img className="artistImg" src="/inc/artysci/artist_13.jpg" />
                      <span className="artistName">Maciej (czlowiek_muryn_barber)</span>
                      <span className="artistProffesion">Barber</span>
                      <a className="portfolioButton" href="https://instagram.com/czlowiek_muryn_barber?igshid=YmMyMTA2M2Y=" target="_blank">Portfolio</a>
                  </div> */}
                </div>
              </div>
            </section>
        <Footer />
      </>
    )
  }
  
  export default BarberShop;
  