import Footer from './footer';
import React, { Suspense, lazy } from 'react';
import ArtistsRow from './artistsRow';

const Artists = () => {

  const Artists = React.lazy(() => import('./artistsRow'));

  return (
    <>
      <div className="mainView">
        <div className="text col-md-6 col-sm-8 col-12">
          <h1>Artyści</h1>
          <p>W naszym zespole pracuje niezwykle utalentowana <span className="sierotki">i pozytywna</span> grupa artystów, swobodnie poruszająca się po zróżnicowanych formach <span className="sierotki">i kolorystyce</span>. Każdy posiada swój unikalny styl <span className="sierotki">i wyjątkowe</span> umiejętności, które zaspokoją nawet najbardziej kreatywną <span className="sierotki">i wymagającą</span> wyobraźnię klienta.</p>
          <p>Nie nakładamy żadnych ograniczeń, co w efekcie daje bardzo szeroką paletę wykonywanych u nas tatuaży.</p>
          <p>Zapraszamy Cię serdecznie do zapoznania się <span className="sierotki">z pracami</span> naszych artystów - jesteśmy przekonani, <span className="sierotki">że znajdziesz</span> coś dla siebie.</p>
        </div>
      </div>
      <Suspense fallback={<div>Loading.....</div>}>
        <Artists />
      </Suspense>
      <Footer />
    </>
  )
}

export default Artists;
